const INFO = {
	main: {
		title: "Gavin.gold",
		name: "Gavin V.",
		email: "vanhussengavin@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/vanhussengavin",
		github: "https://github.com/pggavin",
		linkedin: "https://www.linkedin.com/in/gavinvanhussen/",
		instagram: "https://instagram.com/",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "UI/UX & Game Programming: Simple, Functional, Playful",
		description:
			"Welcome! I love working with engines like Unreal & Unity, with a focus on UI/UX & game programming. I've a lot of time working with both game designers & digital designers, and I believe that the most defining part of a game is its polish. I love working in teams, and I enjoy the process of iterating and refining ideas together to make experiences that resonate with players. ",
	},

	about: {
		title: "I’m Gavin Van Hussen. Crafting digital experiences from Vancouver, BC.",
		description:
			"Throughout my career, I've had the opportunity to work on a diverse array of projects, and each one has contributed to my growth as a professional. I have a particular passion for game development, combining my skills in UI/UX design with robust programming to create immersive experiences. I'm a proponent of open-source development and enjoy when others engage with my work—whether to learn, contribute or offer fresh perspectives. If my projects pique your interest, I welcome you to dive into the code, provide suggestions, or share your insights. Collaboration is the cornerstone of innovation, and I'm always eager to connect and create with fellow enthusiasts."
	},
	articles: {
		title: "Hi! I’m all about making things work beautifully and sharing insights along the way.",
		description:
			"Dive into my collection of articles where I chat about my past work, my programming and design process, and the occasional deep-dive into how I solve design challenges. It’s a casual space for sharing knowledge and documenting my learning process."
	},

	projects: [
		{
			title: "Project 1",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 2",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 3",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 4",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 5",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},
	],
};

export default INFO;
